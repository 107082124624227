.content {
    background-image: url("../../public/img/background.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-content {
    background: #FFFFFF;
    box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.1);
    border-radius: 19px;
    padding: 30px !important;
    padding-top: 7vh !important;
    padding-bottom: 7vh !important;
    width: 33%;
    min-height: 70%;
    overflow-y: auto;
}

.login-header-title {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 1000 !important;
    font-size: 1.8vw !important;
    line-height: 31px !important;
    color: #333333 !important;
}

.login-common-title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1.1vw;
    line-height: 1.2vw;
    color: #727D91 !important;
}

.verify-container {
    // width: 100%;
    height: 40px;
    justify-content: center;
}

.verify-character {
    background: #F8F8FD;
    border: 1px solid #EAEAEA;
    border-radius: 5px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    color: #ABB4C5;
    height: 40px !important;
    width: 40px !important;
}

.login-input .ant-form-item-label label{
    font-size: 1vw;
    color: #727D91;
}

.login-input input{
    font-size: 1vw;
    color: #727D91;
}

.login-input .ant-input-affix-wrapper > input.ant-input {
    font-size: 1vw !important;
    color: #727D91;
}

.login-content .long-button span {
    font-size: 1vw;
}